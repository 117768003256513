const hostname = window.location.hostname.toString();

export class User {

  splitHost = hostname.replace('www.','').split('.');

  constructor() {
    this.network = this.getNetwork()
  }

  getNetwork(){
    return this.splitHost[0];
  }
}
