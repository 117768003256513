<script setup>
import { ref } from 'vue';
const imageError = ref(false);

const handleImageError = () => {
  imageError.value = true;
};
</script>

<template>
  <div>
    <img class="d-block"
         width="200"
         :src="user.data.logoUrl"
         v-if="user.data.logoUrl !== '' && !imageError"
         @error="handleImageError"
    />
    <h1 v-else>{{ user.network }}</h1>
  </div>
</template>
