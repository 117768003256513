import { format } from 'v-money3';
import { toRaw, reactive } from 'vue';
import { useGtm } from '@gtm-support/vue-gtm';
import { eiqMappingTranslations } from "@/core/textConstants";
import { login, sendToKafka } from '@/api/apiCommon';
import MurmurHash from 'imurmurhash';

const loggingEnabled = import.meta.env.VITE_LOGGING === 'enabled';

const trackGTMEvent = (eventObject) => {
  // example object
  // {
  //   event: 'event name',
  //   category: 'category',
  //   action: 'click',
  //   label: 'My custom component trigger',
  //   value: 5000,
  //   noninteraction: false,
  // }
  const gtm = useGtm();

  gtm.trackEvent(eventObject);
};

const pushToDataLayer = (object) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(object);
}

const deepClone = (obj) => {
  let rawObj = toRaw(obj);

  if (rawObj === null || typeof rawObj !== 'object') {
    return rawObj;
  }

  const clone = Array.isArray(rawObj) ? [] : {};

  for (const key in rawObj) {
    if (Object.prototype.hasOwnProperty.call(rawObj, key)) {
      clone[key] = deepClone(rawObj[key]);
    }
  }

  return reactive(clone);
};

const jsonToFormData = (json, formData = new FormData(), parentKey = '') => {
  for (let key in json) {
    if (json.hasOwnProperty(key)) {
      let newKey = parentKey ? `${parentKey}[${key}]` : key;
      if (typeof json[key] === 'object' && json[key] !== null) {
        jsonToFormData(json[key], formData, newKey);
      } else {
        formData.append(newKey, json[key]);
      }
    }
  }
  return formData;
}

function isEmptyObject(obj) {
  return obj == null || (typeof obj === 'object' && Object.values(obj).every(isEmptyObject));
}

const shuffle = (array) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};


const presentBoolean = (value) => {
  return value ? 'Ναι' : 'Όχι';
}

const presentDate = (value) => {
  return new Date(value).toLocaleString('el-GR', { day: '2-digit', month: '2-digit', year: 'numeric' });
}

const presentDateOnlyMonth = (value) => {
  return new Date(value).toLocaleString('el-GR', { month: '2-digit', year: 'numeric' });
}

const presentCurrency = (value) => {
  return format(value, moneyOptionsNoDecimal);
}


const presentVehicleType = (value) => {
  return eiqMappingTranslations[value] ?? '';
}

const presentOwner = (value) => {
  switch (value) {
    case 0:
      return 'Ιδιώτης';
    case 1:
      return 'Ιδιώτης';
    case 2:
      return 'Ιδιώτης';
    case 3:
      return 'Εταιρεία';
    case 4:
      return 'Ιδιώτης';
    default:
      return '';
  }
}


const getDifferentFilters = (a, b) => {
  const differentFilters = [];
  for (const key in a) {
    if (JSON.stringify(a[key]) !== JSON.stringify(b[key])) {
      differentFilters.push(key);
    }
  }
  return differentFilters;
};

const isEqual = (a, b) => {
  return JSON.stringify(a) === JSON.stringify(b);
};

// const deepClone = (obj) => {
//   if (obj === null || typeof obj !== 'object') {
//     return obj;
//   }

//   const clone = Array.isArray(obj) ? [] : {};

//   for (const key in obj) {
//     if (Object.prototype.hasOwnProperty.call(obj, key)) {
//       clone[key] = deepClone(obj[key]);
//     }
//   }

//   return clone;
// };

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}


const moneyOptions = {
  prefix: '',
  suffix: '€',
  decimal: ',',
  thousands: '.',
  precision: 2,
  masked: false,
};

const moneyOptionsNoDecimal = {
  prefix: '',
  suffix: '€',
  decimal: ',',
  thousands: '.',
  precision: 0,
  masked: false,
  modelModifiers: { number: true }
};

const isNumber = (char) => {
  return /^\d$/.test(char);
};

const isCharLetter = (char) => {
  return char.toLowerCase() !== char.toUpperCase();
};

const isCharLetterOrNumber = (char) => {
  return isCharLetter(char) || isNumber(char);
};

const filterUnsupportedGreekCharacters = (character) => {
  const unsupportedGreekCharacters = [
    'Γ',
    'Δ',
    'Θ',
    'Λ',
    'Ξ',
    'Π',
    'Φ',
    'Ω',
    'Σ',
    'Ψ',
  ];

  if (unsupportedGreekCharacters.includes(character)) {
    return false;
  } else {
    return true;
  }
};

const translateLatinToGreek = (character) => {
  const mapping = {
    A: 'Α',
    B: 'Β',
    C: 'Ψ',
    D: 'Δ',
    E: 'Ε',
    F: 'Φ',
    G: 'Γ',
    H: 'Η',
    I: 'Ι',
    J: 'Ξ',
    K: 'Κ',
    L: 'Λ',
    M: 'Μ',
    N: 'Ν',
    O: 'Ο',
    P: 'Π',
    Q: 'Θ',
    R: 'Ρ',
    S: 'Σ',
    T: 'Τ',
    U: 'Θ',
    V: 'Ω',
    W: 'Σ',
    X: 'Χ',
    Y: 'Υ',
    Z: 'Ζ',
  };

  if (mapping[character]) {
    return mapping[character];
  } else {
    return character;
  }
};

const formatWithLeadingZero = (value) => {
  if (value === undefined || value === null || value === '') {
    return;
  }

  return value.toString().padStart(2, '0');
}

// Validate month (1-12)
const isValidMonth = (month) => {
  return month >= 1 && month <= 12;
}

// Validate year, customize range as needed
const isValidYear = (year) => {
  return year >= 1900 && year <= new Date().getFullYear();
}

const focusNextInput = (currentValue, nextInputRef, maxLength) => {
  if (currentValue.length === maxLength) {
    nextInputRef.value.focus();
  }
}

const hashString = (inputString) => {
  return MurmurHash(inputString).result().toString();
}

const logEvent = async (message, eventType, payload) => {
  if (loggingEnabled) {
    try {
      let token = await login()
      await sendToKafka(token, {
        topic: "eiq-insurance-web-app",
        key: hashString(message),
        eventLog: { message, eventType, payload }
      })
    } catch (error) {
    }
  }
}

export {
  moneyOptions,
  moneyOptionsNoDecimal,
  deepClone,
  jsonToFormData,
  isEmptyObject,
  shuffle,
  getDifferentFilters,
  isEqual,
  presentBoolean,
  presentDate,
  presentDateOnlyMonth,
  presentCurrency,
  presentVehicleType,
  presentOwner,
  trackGTMEvent,
  pushToDataLayer,
  sleep,
  isNumber,
  isCharLetter,
  isCharLetterOrNumber,
  filterUnsupportedGreekCharacters,
  translateLatinToGreek,
  formatWithLeadingZero,
  isValidMonth,
  isValidYear,
  focusNextInput,
  logEvent
};
