import { InsuranceCovers } from '../enums/filters';
import { DeductionOptions } from '../enums/filters';
import { InsuranceDurations } from '../enums/filters';
import { ExtraFilters } from '../enums/filters';
import { Sorting } from '../enums/filters';
import { VehicleTypes} from "../enums/filters";

const covers = {
  [InsuranceCovers.RoadsideAssistance]: 'Οδική Βοήθεια',
  [InsuranceCovers.LegalProtection]: 'Νομική Προστασία',
  [InsuranceCovers.CrystalBreakage]: 'Θραύση Κρυστάλλων',
  [InsuranceCovers.PersonalAccident]: 'Προσωπικό Ατύχημα',
  [InsuranceCovers.PartialTheft]: 'Μερική Κλοπή',
  [InsuranceCovers.NaturalPhenomena]: 'Φυσικά Φαινόμενα',
  [InsuranceCovers.MaliciousPersonalInjury]: 'Κακόβουλες Ιδίων Ζημιών',
  [InsuranceCovers.HailDamage]: 'Ζημιές από Χαλάζι',
  [InsuranceCovers.Fire]: 'Πυρός',
  [InsuranceCovers.Theft]: 'Ολική Κλοπή',
  [InsuranceCovers.OwnDamages]: '\'Ιδιες Ζημιές',
  [InsuranceCovers.DamagesFromUninsuredVehicle]: 'Ζημιές από Ανασφάλιστο'
};

const deductionOptions = {
  [DeductionOptions.d0to250.value]: 'Έως 250€',
  [DeductionOptions.d251toMax.value]: '251€ και άνω',
  [DeductionOptions.d251to600.value]: '251€ - 600€',
  [DeductionOptions.d0to500.value]: 'Έως 500€',
  [DeductionOptions.d501to900.value]: '501€ - 900€',
  [DeductionOptions.d901toMax.value]: '901€ και άνω',
  [DeductionOptions.d601to1000.value]: '601€ - 1.000€',
  [DeductionOptions.d1001toMax.value]: '1.001€ και άνω',
  [DeductionOptions.withDeduction.value]: 'Με απαλλαγή',
  [DeductionOptions.withoutDeduction.value]: 'Χωρίς απαλλαγή',
};

const durations = {
  [InsuranceDurations.OneMonth]: '1 Μήνας',
  [InsuranceDurations.ThreeMonths]: '3 Μήνες',
  [InsuranceDurations.SixMonths]: '6 Μήνες',
  [InsuranceDurations.TwelveMonths]: '12 Μήνες',
};

const extraFilters = {
  [ExtraFilters.EasyBuy]: 'Γρήγορη Αγορά',
  [ExtraFilters.Offers]: 'Μόνο Προσφορές',
};

const sortingOptions = {
  [Sorting.ByPrice]: 'Τιμή',
  [Sorting.ByBenefits]: 'Παροχές',
  [Sorting.ByRating]: 'Αξιολόγηση',
};

const categoryTranslations = {
  [VehicleTypes.Car]: {
    title: 'Αυτοκίνητο',
    passive: 'Αυτοκινήτου'
  },
  [VehicleTypes.Bike]: {
    title: 'Μηχανή',
    passive: 'Μηχανής'
  },
  [VehicleTypes.Truck]: {
    title: 'Φορτηγό',
    passive: 'Φορτηγού'
  },
  [VehicleTypes.Rural]: {
    title: 'Αγροτικό',
    passive: 'Αγροτικού'
  },
  [VehicleTypes.Taxi]: {
    title: 'Ταξί',
    passive: 'Ταξί'
  },
  [VehicleTypes.RentalCar]: {
    title: 'Ενοικιαζόμενο Αυτοκινήτο',
    passive: 'Ενοικιαζόμενου Αυτοκινήτου'
  }
}

const eiqMappingTranslations = {
  0: 'ΕΙΧ',
  1: 'ΦΙΧ',
  2: 'ΦΙΧ - Αγροτικό',
  3: 'ΜΟΤΟ',
  4: 'ΤΑΞΙ',
  16: 'ΕΙΧ ΕΝΟΙΚΙΑΖΟΜΕΝΟ'
}

export {
  sortingOptions,
  durations,
  extraFilters,
  covers,
  deductionOptions,
  categoryTranslations,
  eiqMappingTranslations
};
