import { createRouter, createWebHistory } from 'vue-router';
import axios from 'axios';

const validCategories = ["car", "bike", "truck", "rural", "taxi", "rentalcar"]

const fetchFid = async (fid) => {
  try {
    let url = '/carinsurance/getRedisKey';
    if(fid){
      url += `/_f/${fid}`
    }
    return await axios.get(url);
  } catch (error) {
    console.error('Error fetching fid:', error);
    throw error;
  }
};

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/form/:category/:fid?',
      name: 'form',
      component: () => import('../views/forms/CarForm.vue'),
      beforeEnter: async (to, from, next) => {
        // Check if user came from refresh or another route
        if (typeof to.redirectedFrom === 'undefined') {
          try {
            // Fetch a new fid
            const fid = await fetchFid();
            // Redirect to self with new fid param
            next({ ...to, params: { ...to.params, fid: fid.data } });
          } catch (error) {
            next(false); // Cancel navigation on error
          }
        } else if (to.redirectedFrom?.name === 'form') {
          // Proceed if navigating within the form route
          next();
        } else {
          try {
            // Fetch a new fid if coming from a different route
            const fid = await fetchFid();
            // Redirect to self with new fid param
            next({ ...to, params: { ...to.params, fid: fid.data } });
          } catch (error) {
            next(false); // Cancel navigation on error
          }
        }
      }
    },
    {
      path: '/results/:category/:fid',
      name: 'results',
      // route level code-splitting
      // this generates a separate chunk (Results.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/ResultsView.vue'),
    },
    {
      path: '/compare',
      name: 'compare',
      // route level code-splitting
      // this generates a separate chunk (Results.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/Compare.vue'),
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});

// Hook google analytics
// router.afterEach((to, from) => {
//   // console.log(to, from);
//   // sendToAnalytics(to.fullPath);
// });

// Add a global navigation guard
// router.beforeEach((to, from, next) => {
//   // Check if the category parameter is valid for all routes
//   if (isValidCategory(to.params.category)) {
//     alert("VALID")
//
//   } else {
//     // Redirect the user to a different route
//     // next({ name: 'invalidCategory' }); // Assuming you have a route named 'invalidCategory'
//     alert("AAAAAHHHH")
//   }
// });

const isValidCategory = (category) => {
    return validCategories.some(item => item.toLowerCase() === category.toLowerCase()); // Check if any array element matches the lowercase string
}

const isValidHash = (fid, category) => {
  if (!fid) return false;

  // tryOnMounted()
  try {
    const decodedFid = atob(fid).toLowerCase();
    for (let category of validCategories) {
      if (decodedFid.startsWith(category.toLowerCase())) {
        return true;
      }
    }
    return false;
  } catch{
    return false;
  }



}

export default router;
