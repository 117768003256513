import {useUserStore} from "@/stores/user";
import axios from "axios";

const EIQ_SERVER = import.meta.env.VITE_API_COMMON;

const apiInstance = axios.create({})

const login = async _ => {
    let url = EIQ_SERVER + "/token"
    const resp = await apiInstance({method: 'get', url: url})
    return resp.data
}

const getPdf = async (token, data) => {
    let url = EIQ_SERVER + `/pdf/insurance?token=${token}`
    const resp = await apiInstance({method: 'post', url: url, data: data})
    return resp.data
}
const sendToKafka = async (token, data) => {
    let url = EIQ_SERVER + `/event?token=${token}`
    const resp = await apiInstance({method: 'post', url: url, data: data})
    return resp.data
}

const sendEmail = async (token, data) => {
    let url = EIQ_SERVER + `/email/insurance?token=${token}`
    const resp = await apiInstance({method: 'post', url: url, data: data})
    return resp.data
}

export { login, getPdf, sendToKafka, sendEmail }