import * as yup from 'yup';

export const greekMessages = {
  mixed: {
    default: 'Το πεδίο δεν είναι έγκυρο',
    required: 'Το πεδίο είναι υποχρεωτικό',
    oneOf: 'Το πεδίο πρέπει να έχει μία από τις ακόλουθες τιμές: ${values}',
    notOneOf: 'Το πεδίο δεν πρέπει να έχει καμία από τις ακόλουθες τιμές: ${values}',
    notType: ({ type, value, originalValue }) => {
      switch (type) {
        case 'number':
          return `Το πεδίο πρέπει να είναι αριθμός, αλλά η τελική τιμή ήταν: ${JSON.stringify(originalValue)}`;
        case 'string':
          return `Το πεδίο πρέπει να είναι κείμενο, αλλά η τελική τιμή ήταν: ${JSON.stringify(originalValue)}`;
        // Add other types as needed
        default:
          return `Το πεδίο έχει λάθος τύπο:  ${JSON.stringify(type)}. Τρέχουσα τιμή: ${JSON.stringify(value)}. Αρχική τιμή: ${JSON.stringify(originalValue)}`
      }
    },
    defined: 'Το πεδίο πρέπει να έχει ορισμένη τιμή',
    wrongLicensePlateFormat: 'Λανθασμένη μορφή πινακίδας κυκλοφορίας',
    isAfterOrSameAsManufactureDate: 'Η ημερομηνία μεταβίβασης πρέπει να είναι μεταγενέστερη ή ίση της ημερομηνίας κατασκευής',
    isValidDate: 'Μη έγκυρη ημερομηνία',
    isOfDrivingAge: 'Μη αποδεκτή ηλικία οδηγού/ιδιοκτήτη',
    isOfDrivingAge2: 'Μη αποδεκτή ηλικία οδηγού/συνιδιοκτήτη'
  },
  string: {
    length: 'Το πεδίο πρέπει να έχει ακριβώς ${length} χαρακτήρες',
    min: 'Το πεδίο πρέπει να έχει τουλάχιστον ${min} χαρακτήρες',
    max: 'Το πεδίο πρέπει να έχει μέχρι ${max} χαρακτήρες',
    matches: 'Το πεδίο δεν ταιριάζει με το απαιτούμενο μοτίβο',
    email: 'Το πεδίο πρέπει να είναι ένα έγκυρο email',
    url: 'Το πεδίο πρέπει να είναι ένα έγκυρο URL',
    trim: 'Το πεδίο δεν πρέπει να περιέχει κενά στην αρχή ή στο τέλος',
    lowercase: 'Το πεδίο πρέπει να περιέχει μόνο πεζά γράμματα',
    uppercase: 'Το πεδίο πρέπει να περιέχει μόνο κεφαλαία γράμματα',
  },
  number: {
    min: 'Το πεδίο πρέπει να είναι μεγαλύτερο ή ίσο με ${min}',
    max: 'Το πεδίο πρέπει να είναι μικρότερο ή ίσο με ${max}',
    lessThan: 'Το πεδίο πρέπει να είναι μικρότερο από ${less}',
    moreThan: 'Το πεδίο πρέπει να είναι μεγαλύτερο από ${more}',
    notEqual: 'Το πεδίο δεν πρέπει να είναι ίσο με ${notEqual}',
    positive: 'Το πεδίο πρέπει να είναι θετικός αριθμός',
    negative: 'Το πεδίο πρέπει να είναι αρνητικός αριθμός',
    integer: 'Το πεδίο πρέπει να είναι ακέραιος αριθμός',
  },
  date: {
    min: 'Το πεδίο πρέπει να είναι μετά ή ίσο με ${min}',
    max: 'Το πεδίο πρέπει να είναι πριν ή ίσο με ${max}',
  },
  boolean: {
    isValue: 'Το πεδίο πρέπει να είναι ${value}',
  },
  object: {
    noUnknown: 'Το πεδίο δεν μπορεί να έχει κλειδιά που δεν έχουν οριστεί στο σχήμα του αντικειμένου',
  },
  array: {
    min: 'Το πεδίο πρέπει να έχει τουλάχιστον ${min} στοιχεία',
    max: 'Το πεδίο πρέπει να έχει το πολύ ${max} στοιχεία',
  },
};


// Plugin to set yup locale
export const yupLocalizationPlugin = {
  install() {
    yup.setLocale(greekMessages);
  },
};
