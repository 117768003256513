<script setup>
import { RouterView } from 'vue-router';
import TheHeader from './components/TheHeader.vue';
import TheFooter from './components/TheFooter.vue';
import { storeToRefs } from 'pinia';
import { provide } from 'vue';
import { useBreakpoints } from '@vueuse/core';
import { useUserStore } from "@/stores/user";

const breakpoints = useBreakpoints({
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
});

const isMobile = breakpoints.smaller('xl');
provide('isMobile', isMobile);
const userStore = useUserStore()

const isLessMd = breakpoints.smaller('md')
provide('isLessMd', isLessMd)

</script>

<template>
  <script src="https://cdn-eu.pagesense.io/js/insurancemarket/2286609555934602bbc2d5e546ffd8e0.js"></script>
  <script type="text/javascript">(function(w,s){var e=document.createElement("script");e.type="text/javascript";e.async=true;e.src="https://cdn-eu.pagesense.io/js/insurancemarket/2286609555934602bbc2d5e546ffd8e0.js";var x=document.getElementsByTagName("script")[0];x.parentNode.insertBefore(e,x);})(window,"script");</script>
  <div class="app">
      <TheHeader v-if="!userStore.isPanelIFrame()"/>
    <main>
      <!-- route outlet -->
      <!-- component matched by the route will render here -->
      <RouterView />
    </main>
      <TheFooter v-if="!userStore.isPanelIFrame()"/>
  </div>
</template>

<style lang="scss">
// Sass entry point
@import 'assets/sass/entry';
</style>
