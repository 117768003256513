<script setup>
import { inject } from 'vue';
import { useQuoteStore } from '@/stores/quote';
import TheLogo from "@/components/TheLogo.vue";
import {useUserStore} from "@/stores/user";
const quoteStore = useQuoteStore();
const userStore = useUserStore();
const isMobile = inject('isMobile');
</script>

<template>
  <header class="bg-white">
    <div class="container">
      <div class="d-flex align-items-center py-5 py-xl-6">
        <a
            href="/"
            class=""
            :class="{ 'mw-130': isMobile }"
        >
          <TheLogo v-if="!userStore.isPanelIFrame()"/>
        </a>

        <a
          v-if="user.data.phone"
          :href="`tel: ${user.data.phone}`"
          class="ms-auto text-reset text-decoration-none"
        >
          <div class="d-flex flex-column gap-1">
            <div class="d-flex align-items-center ms-auto">
              <vue-feather
                class="me-1"
                type="phone"
                stroke="#29ADAB"
                :size="isMobile ? 14 : 24"
              ></vue-feather>
              <div class="">{{ user.data.phone }}</div>
            </div>
            <div v-if="userStore.isIm()" class="fs-6 text-n600">Είμαστε πάντα δίπλα σας 24/7</div>
          </div>
        </a>
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.st0 {
  fill: #0e7275;
}
.st1 {
  fill: #00b2b8;
}
</style>
