import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/offcanvas';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import VueFeather from 'vue-feather';
import Toast from 'vue-toastification';
import { createGtm } from '@gtm-support/vue-gtm';
import { User } from "./user";
import axios from "axios";
import {useUserStore} from "@/stores/user";
import { yupLocalizationPlugin } from './plugins/yup-localization';

const initFilesS3URL = "https://web-app-configuration.s3.eu-west-1.amazonaws.com";
const initVueApp = async (user) => {
  const app = createApp(App);
  app.component(VueFeather.name, VueFeather);

  const options = {
    icon: false,
    timeout: 10000,
    transition: 'Vue-Toastification__fade',
  };
  app.use(Toast, options);

  // Add window object as a global property
  app.config.globalProperties.window = window;
  app.config.globalProperties.version = window.__IM_FORMS_AND_RESULTS_VERSION__;
  app.config.globalProperties.user = user;

    app.use(createPinia());

    const t = useUserStore()
    app.use(router);

  app.use(
    createGtm({
      id: 'GTM-P446RJ', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
      defer: true, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
      compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
      enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
      debug: true, // Whether to display console logs debugs (optional)
      loadScript: true, // Whether to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
      vueRouter: router, // Pass the router instance to automatically sync with router (optional)
      trackOnNextTick: false, // Whether to call trackView in Vue.nextTick
    }),
  );

  // Use the localization plugin for yup
  app.use(yupLocalizationPlugin);
  app.mount('#app');
};

const fetchUserData = async () => {
  try {
    let user = new User();
    let userData = await axios.get(`${initFilesS3URL}/${user.network}.json`);
    user.data = userData.data;
    return user
  } catch (error) {
    throw Error(error)
  }
};

const init = async () => {
  await initVueApp(await fetchUserData());
};

init();
