const VehicleTypes = Object.freeze({
  Car: 'car',
  Bike: 'bike',
  Truck: 'truck',
  Rural: 'rural',
  Taxi: 'taxi',
  RentalCar: 'rentalcar',
  CrmCar: 'crmcar',
  CrmBike: 'crmbike',
  CrmTruck: 'crmtruck',
  CrmRural: 'crmrural',
  CrmTaxi: 'crmtaxi',
  CrmRentalCar: 'crmrentalcar',
});

const InsuranceTypes = Object.freeze({
  Basic: 'BASIC',
  Extra: 'EXTRA',
  Premium: 'PREMIUM',
});

const TextConstants = {
  [InsuranceTypes.Basic]: 'Βασικό',
  [InsuranceTypes.Extra]: 'Πυρός-Κλοπής',
  [InsuranceTypes.Premium]: 'Μικτή',
};


const InsuranceDurations = Object.freeze({
  // We are launching with rural that doesnt currently support 1 month. For the other vehicle types this needs to be connected with the supported vehicle types.
  OneMonth: 1,
  ThreeMonths: 3,
  SixMonths: 6,
  TwelveMonths: 12,
});

const InsuranceCovers = Object.freeze({
  CrystalBreakage: '038ad68424a299dcc08ae6fe66e6cff3',
  MaliciousPersonalInjury: '1c0aca3a402fda2b8c6848527160dc24',
  PartialTheft: '0946f5c3ab061145147e2c5f94d3a933',
  LegalProtection: '0f94adc0be6c83f1ce45136263186e60',
  Theft: '87e4e58a68b99439a046d43777e54798',
  PersonalAccident: 'af241d6ca0fb0a4386e5f20e67d016f0',
  Fire: '97922a4285646742aca839a700add2f4',
  RoadsideAssistance: '2c8ce1fb6646495ba1d15c5c81bad1b3',
  AirbagDamage: '13776bbca037c5f6e63caeab159e1dfc',
  InsuranceProtection: 'ff976a89368d5736e0fc990cf5bb1a02',
  HailDamage: '662f3c11b81157d3c636a29d579a9d7b',
  NaturalPhenomena: 'ec0aa33177ee6f89e2744928148e9217',
  OwnDamages: 'b1ada174c523d553c94c6e820af2b484',
  DamagesFromUninsuredVehicle: 'f57b212f2f5b7e47c2218856e0ec4b1e'
});

const CoverAvailabilityStatus = Object.freeze({
  Included: 'included',
  NotIncluded: 'not_included',
  AvailableToAdd: 'available_to_add',
});

const DeductionOptions = Object.freeze({
  d0to250: {
    value: 'd0to250',
    filter: (deduction) => {
      return deduction <= 250;
    },
  },
  d0to500: {
    value: 'd0to500',
    filter: (deduction) => {
      return deduction <= 500;
    },
  },
  d251toMax: {
    value: 'd251toMax',
    filter: (deduction) => {
      return deduction > 250;
    },
  },
  d251to600: {
    value: 'd251to600',
    filter: (deduction) => {
      return deduction > 250 && deduction <= 600;
    },
  },
  d501to900: {
    value: 'd501to900',
    filter: (deduction) => {
      return deduction > 500 && deduction <= 900;
    },
  },
  d901toMax: {
    value: 'd901toMax',
    filter: (deduction) => {
      return deduction > 900;
    },
  },
  d601to1000: {
    value: 'd601to1000',
    filter: (deduction) => {
      return deduction > 600 && deduction <= 1000;
    },
  },
  d1001toMax: {
    value: 'd1001toMax',
    filter: (deduction) => {
      return deduction > 1000;
    },
  },
  withoutDeduction: {
    value: 'withoutDeduction',
    filter: (deduction) => {
      return deduction === 0;
    },
  },
  withDeduction: {
    value: 'withDeduction',
    filter: (deduction) => {
      return deduction > 0;
    },
  },
});

const DeductionGroups = Object.freeze({
  groupA: [DeductionOptions.d0to250, DeductionOptions.d251toMax],
  groupB: [
    DeductionOptions.d0to250,
    DeductionOptions.d251to600,
    DeductionOptions.d601to1000,
    DeductionOptions.d1001toMax,
  ],
  groupC: [
    DeductionOptions.d0to250,
    DeductionOptions.d251to600,
    DeductionOptions.d601to1000,
  ],
  groupD: [
    DeductionOptions.withoutDeduction,
    DeductionOptions.withDeduction,
  ],
  groupE: [
    DeductionOptions.d0to500,
    DeductionOptions.d501to900,
    DeductionOptions.d901toMax,

  ]
});

const ExtraFilters = Object.freeze({
  EasyBuy: 'easyBuy',
  Offers: 'offersOnly',
});

const Sorting = Object.freeze({
  ByPrice: 'price',
  ByBenefits: 'benefits',
  ByRating: 'rating',
});



export {
  VehicleTypes,
  InsuranceTypes,
  TextConstants,
  InsuranceDurations,
  InsuranceCovers,
  DeductionOptions,
  ExtraFilters,
  DeductionGroups,
  Sorting,
  CoverAvailabilityStatus,
};
