import { defineStore } from 'pinia';
import axios from "axios";
import {computed, ref} from "vue";

const api  = axios.create({
    baseURL: 'https://web-app-configuration.s3.eu-west-1.amazonaws.com',
    timeout: 2000
});

const retrieveUserConfiguration = async (user) => {
    try {
        const response = await api.get(`/${user}.json`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const useUserStore = defineStore('user',  () => {
    let data = ref({})

    /*isIm is true for both site and CRM*/
    const isIm = () => {
        return location.hostname.includes("insurancemarket")
    }
    const isPanelIFrame = () => {
        return window.location !== top.location && !window.location.hostname.toString().includes('my-pro-office')
    }

    const apiInstance = () => {
        return axios.create({
            headers: {
                'Request-Source': isPanelIFrame() ? 'CRM' : 'SITE',
                'X-Requested-With': 'xmlhttprequest',
                'EIQ-Payload': 'true',
            }
        });
    }

    if(!isPanelIFrame()){
        data.value = retrieveUserConfiguration(window.location.hostname.replace('www.', '').split('.')[0])
    }

    return { isIm, isPanelIFrame, apiInstance, data }

});